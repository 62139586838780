<template>
    <div class="wrap">
        <div class="main_title">Calendar</div>
        <div class="calendar">
            <a-calendar v-model:value="value">
                 <template #dateCellRender="{ current: value }">
                    <div class="events">
                        <span class="calendar_cell" v-for="(task, index) in getLabel(value)" :key="index">
                             <a-badge status="success" text="Installation"  @click="sing(task)"/>
                        </span>
                    </div>
                </template>
            </a-calendar>
            <a-drawer
                title="Order"
                placement="bottom"
                height="100%"
                :visible="modalOrderStatus"
                @close="() => modalOrderStatus = false"
                :drawerStyle="{background: '#f9f9f9'}"
                >
                <div class="container">
                    <OrderInfo :orderInfo="orderInfo" />
                </div>
            </a-drawer>
        </div>
    </div>
</template>
<script>
import { getTaskListForEmployee,getTaskStreamAll } from '@/apis/employee/task'
import moment from "moment"
import OrderInfo from '@/components/admin/order/orderInfo'
export default {
    name: 'Calendar',
    data(){
        return {
            value: moment().format('YYYY-MM-DD'),
            taskList: [],
            taskInfo:{},
            orderInfo: {},
            modalOrderStatus: false,
        }
    },
    components: {
        OrderInfo
    },
    methods: {
        async getTaskListForEmployeeHandler(){
            const res = await getTaskListForEmployee({page: 1, page_size: 1000})
            this.taskList = res.list.rows
        },
        async sing(task){
            this.taskInfo=task
            this.orderInfo=this.taskInfo.om
            const ts=await getTaskStreamAll({task_id:this.taskInfo.task_id})
            if(ts.ts.length>0){
                this.orderInfo.orderStreams=ts.ts[0]
            }        
            this.modalOrderStatus = true
        },
    },
    computed: {
        getLabel(){
            return value => {
               const date = value.unix()
               const data = this.taskList.filter(r => (r.om.instation_date ? moment(r.om.instation_date).unix() : 0) == date)
               return data
            }
        }
    },
    created(){
        this.getTaskListForEmployeeHandler()
    }
}
</script>
<style lang="stylus" scoped>
.calendar_cell
    display flex
    flex-direction column
</style>