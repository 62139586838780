import request from '@/request'
export const getTaskListForEmployee = (data = {}) => {
    return request({
      url: '/employee/task/get_task_list',
      method: 'get',
      params: data
    })
}
export const distTask = (data = {}) => {
  return request({
    url: '/employee/task/dist_task',
    method: 'post',
    data
  })
}
export const sendTask = (data = {}) => {
  return request({
    url: '/employee/task/send_task',
    method: 'post',
    data
  })
}
export const finishTask = (data = {}) => {
  return request({
    url: '/employee/task/finish_task',
    method: 'post',
    data
  })
}
export const getTaskFile= (data = {}) => {
  return request({
    url: '/employee/task/get_task_file',
    method: 'get',
    params: data
  })
}
export const updateTaskFile = (data = {}) => {
  return request({
    url: '/employee/task/update_task_file',
    method: 'post',
    data
  })
}
export const getTaskLogs = (data = {}) => {
  return request({
    url: '/employee/task/get_task_logs',
    method: 'get',
    params: data
  })
}

export const getTaskStreamAll = (data = {}) => {
  return request({
    url: '/employee/task/get_task_stream_all',
    method: 'get',
    params: data
  })
}